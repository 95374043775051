import React, { useState } from "react";
import Calendar from "./calendar";
import Container from "@mui/material/Container";
import { useSetting } from "../hooks";

export default function Home() {
  const appSettings = useSetting();
  
  return (
    <>
      <Container sx={{ pt: 10 }} fixed>
        <Calendar appSettings={appSettings}/>
      </Container>
    </>
  );
}
