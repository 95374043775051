import { useState, useEffect } from "react";
import { auth } from "../services/firebase.google.service";
import { onAuthStateChanged } from "firebase/auth";

export default function useAuthListener() {
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    const [user, setUser] = useState(authUser);
    useEffect(() => {
        const listener = onAuthStateChanged(auth, (currentUser)  => {
            if (currentUser) {
                localStorage.setItem("authUser", JSON.stringify(currentUser));
                setUser(currentUser);
            } else {
                localStorage.removeItem("authUser");
                setUser(null);
            }
        });
        return () => listener();
    }, []);

    return { user };
}
