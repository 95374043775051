import React, { useState } from 'react'
import { auth } from '../services/firebase.google.service';
import { signInWithEmailAndPassword } from "firebase/auth";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from "@mui/material/Button";

export default function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [submit, setSubmit] = useState(false);
    const [error, setError] = useState({ action: false, msg: ''});
    const isInvalid = username === "" || password === "";

    const handleLogin = async (e) => {
        e.preventDefault();
        setSubmit(true)
        try {
            await signInWithEmailAndPassword(auth, username, password); 
            setSubmit(false)
            setError({action: false, msg:'' })
        } catch (error) {
            setError({action: true, msg: error.message })
            setSubmit(false)
        }
    };

    return (
        <>
            <Container style={{ display: 'flex', justifyContent:'center', alignItems: 'center'}} sx={{ width: '100%', height: '100vh' }} fixed>
                <Box sx={{ width: '40%', height: '40%', mb: 25 }}>
                    <Paper sx={{ width: '100%', height: '100%', p: 5 }} elevation={8}>
                        <Typography sx={{ width: '100%', textAlign: 'center' }} variant="h4" component="div" gutterBottom>
                            เข้าสู่ระบบขอ Off, Va
                        </Typography>
                        <TextField type="email" sx={{ my: 1 }} fullWidth label="อีเมลล์" onChange={(event) => setUsername(event.target.value) }/>
                        <TextField type="password" sx={{ my: 1 }} fullWidth label="พาสเวิร์ด" onChange={(event) => setPassword(event.target.value) }/> 
                        { error.action ? <p style={{color: 'red', margin: 0}}> Error : { error.msg } </p> : false }
                        <Button sx={{ my: 4, float: 'right', px: 4 }} variant="contained" onClick={handleLogin} disabled={submit || isInvalid }>เข้าสู่ระบบ</Button>
                    </Paper>
                </Box>
            </Container>
        </>
    )
}
