import { useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../services/firebase.google.service";

export default function useSetting() {
    const [setting, setSetting] = useState([]);

    useEffect(() => {
        const docRef = doc(db, "AppSettings", "0");
        getDoc(docRef)
        .then((snapshot) => {
            if (snapshot.exists()) {
                setSetting(snapshot.data());
            } else {
                console.log("No such document!");
            }
        })
        .catch(error => {
            console.log(error.message);
        })
    }, []);

    return { setting };
}
