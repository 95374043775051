import React, { useEffect } from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"

export default function Calendar() {
  const props = [
    // { title: 'Theeraprasert meemui', date: '2022-01-12', id: 'dadadad',},
    // { title: 'Theeraprasert meemui', date: '2022-01-12', id: 'dadadad' },
  ]
  const calendarRef = React.createRef()

  const handleDateClick = (event) => {
    // TODO ADD EVENT
    console.log(event);
  }

  const headerToolbar = { start: '', center: '', end: '' }


  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.next()
  }, [])

  return (
    <FullCalendar
      ref={calendarRef}
      headerToolbar={headerToolbar}
      plugins={[ dayGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      events={props}
      dateClick={handleDateClick}
      navLinks= {false}
      editable= {true}
      eventLimit= {true}
      eventContent={renderEventContent}
    />
  )
}

function renderEventContent(eventInfo) {
  console.log(eventInfo);

  const handleClick = (event) => {
    // TODO DELETE EVENT
    console.log(event);
  }

  return (
    <>
      <p>{eventInfo.event.title}</p>
      <button onClick={() => handleClick(eventInfo.event) }>x</button>
    </>
  )
}
